'use client';
import {
  AppBar as MuiAppBar,
  Box,
  Toolbar,
  useMediaQuery,
  useTheme
} from '@mui/material';
import React from 'react';
import Image from 'next/image';

export type AppBarProps = {
  rightContent?: React.ReactElement | null | undefined;
  progress?: React.ReactElement | null | undefined;
  orderSummary?: React.ReactElement | null | undefined;
};

export function AppBar({ rightContent, progress, orderSummary }: AppBarProps) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <MuiAppBar
      position="fixed"
      sx={{
        background: 'white',
        color: 'black',
        minHeight: '80px',
        maxHeight: '80px',
        minWidth: '100vw'
      }}
    >
      <Toolbar sx={{ verticalAlign: 'center' }}>
        <Box
          minHeight="80px"
          maxHeight="80px"
          minWidth="100%"
          display="flex"
          justifyItems="space-between"
          justifyContent="space-between"
          alignContent="center"
          alignItems="center"
          alignSelf="center"
        >
          <Box
            sx={{
              ':hover': {
                cursor: 'pointer'
              },
              position: 'relative'
            }}
            onClick={() => {
              window.location.href = 'https://www.emporiaenergy.com';
            }}
            minWidth={matches ? '155px' : '120px'}
            minHeight="80px"
            top={0}
            left={0}
          >
            <Image
              src="/emporia_logo.svg"
              alt="emporia logo"
              fill
              style={{
                objectFit: 'contain'
              }}
            />
          </Box>
          {rightContent ? (
            <Box
              display="flex"
              flexGrow={1}
              alignContent="center"
              minHeight="80px"
              alignItems="center"
              justifyContent="right"
            >
              {rightContent}
            </Box>
          ) : null}
        </Box>
      </Toolbar>
      {progress}
      {orderSummary}
    </MuiAppBar>
  );
}
